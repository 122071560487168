import {Button} from 'react-bootstrap'
import React from "react"
import modalEventsFunction from "./modalEvents.js"
import "./modal.scss"

export default class ModalOverlay extends React.Component {

  constructor(props){
  super(props);
  this.state = {
    modalState: this.props.modalState
  }
}

handleClick = () => {
  if (this.state.modalState === "off"){
    this.setState({
      modalState: "on"
    })
  }
  else{
    this.setState({
      modalState: "off"
    })
  }

}


  componentDidMount() {
  	// modalEventsFunction();
  }

	render(){

		return (
      <div className="modalOuterWrap" id={this.props.id} style={{display:'inline',whiteSpace:'nowrap'}}>
        <nobr/><span onClick={this.handleClick} >{this.props.children}</span>
  			<div className={this.state.modalState + " modal"} tabIndex="-1" role="dialog">
        <div onClick={this.handleClick} className="modalOuterClose"></div>
  			  <div className="modal-dialog" role="document">
  			    <div className="modal-content">
  			      <div className="modal-header">
  			        <button onClick={this.handleClick} type="button" className="close modal-close" data-dismiss="modal" aria-label="Close">
  			        </button>
  			      </div>
  			      <div className="modal-body">
  			        <p className="modal-message">{this.props.message}</p>
  			        <p><Button className="modal-url" target="_blank" rel="noopener noreferrer" href={this.props.link}>{this.props.buttontext}</Button></p>
  			      </div>
  			    </div>
  			  </div>
  			</div>

      </div>

		)
	}

}

ModalOverlay.defaultProps =
  {
    modalState: "off",
    message : "Modal body text goes here",
    buttontext: "CONTINUE",
    link : "https://vascepa.com/"
  }
