import {Nav} from 'react-bootstrap'
import React from "react"
import navigationActive from "./navigationActive.js"
import { Link } from "gatsby"

export default class Navigation extends React.Component {


  componentDidMount() {
    navigationActive();
  }

  constructor(props){
    super(props);
    this.state = {
    }
  }

  getNavItem(navItem){

    switch(navItem.type){
      case 'link':
        return <Nav.Item className={navItem.className}><Link className="nav-link" to={navItem.target}>{navItem.content}</Link></Nav.Item>
      default:
        // default link
    }
  }

  render(
  ){
    return(

      <Nav justify className="mr-auto">
        {this.props.navContent.map(navItem => this.getNavItem(navItem))}
      </Nav>

    )
  }
}
