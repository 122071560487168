import { Link } from "gatsby"
import React from "react"
import ClientLogo from "./amarin-logo.svg"
import ModalOverlay from "../modal/modal"
const Footer = ({ className, vivCode, displayStyle }) => (

	<footer className={className}>
		<ModalOverlay
			message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com",<br/>,<p>You will be directed to another Amarin <br className = "mobile-break"/>website to learn about a prescription <br className = "mobile-break"/>cardiovascular treatment.</p>]}
			buttontext = "Continue"
			link = "https://vascepa.com/"
			>
		<div className="footer-header">

				<span className="d-lg-inline d-xl-inline">DOES YOUR HEART NEED MORE? <br className = "mobile-break"/><u>DISCOVER A PRESCRIPTION TREATMENT OPTION</u></span>

		</div>
	</ModalOverlay>

		<span style={{display: displayStyle}} className="footer-start text-uppercase"><p style={{marginBottom:"20px"}}>Click <u><ModalOverlay
				link="https://vascepahcp.com/"
				buttontext="Continue"
				message={[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com",<br/>,<p>You will be directed to another Amarin <br className = "mobile-break"/>website to learn about a prescription <br className = "mobile-break"/>cardiovascular treatment.</p>]}
				modalState ="off"
			><strong>here</strong></ModalOverlay></u> if you are a healthcare professional</p></span>


		<p>
		{/* <Link to="/references" rel="noopener noreferrer">Full Reference List</Link> <span className="spacer">|</span> */}
		<a href="https://amarincorp.com/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a> <span className="spacer">|</span> <a target="_blank" href="https://amarincorp.com/privacy_policy.html" rel="noopener noreferrer">Privacy Policy</a></p>

		<p>The Amarin logo is a registered trademark of Amarin Group of Companies. All other trademarks are the property of their respective companies.</p>

		<p>
			&copy; 2022 Amarin Pharmaceuticals Ireland Limited.<br/> All rights reserved.<br/>Contact Us: 1-855-CVTRUTH<br/>
			Amarin Group of Companies is not responsible for material contained on other non-Amarin controlled websites or other social media sites.<br/>

		AMRN-{vivCode} &nbsp; 03/22

		</p>

		<p>

				<a target="_blank" href="https://amarincorp.com/"><img id="amarin-cta" alt="Amarin" src={ClientLogo} /></a>
		</p>

	</footer>

)


export default Footer
