import React from "react"
import "./loader.scss"

export default class Loader extends React.Component {
  constructor(props){
    super(props)
    this.state = {
       display: "block"
    }



  }


  handleLoad = () => {
    this.setState({
      display: "none"
    })
  }

  componentWillMount(){}

  componentDidMount(){
    this.handleLoad()
  }






  render(){

    return (

      <div style={{display:this.state.display}} class="loader">{this.props.children}</div>

    )
  }

}
