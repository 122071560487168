import {Navbar} from 'react-bootstrap'
import {Link} from 'gatsby'
import PropTypes from "prop-types"
import React from "react"
import Navigation from "../navigation/navigation.js"
import Logo from "./true-to-your-heart.png"
import LogoMobile from "./heart-svg-updated.svg"


const Header = ({ siteTitle }) => (
  <header id="header">
    <Navbar bg="light" expand="lg">
      <Navbar.Brand>
        <Link to="/">
          <img
            src={Logo}
            alt="True to Your Heart"
            className="d-none d-lg-block logo-image logo-desktop"
          />
          <img
            src={LogoMobile}
            alt="True to Your Heart"
            className="d-lg-none logo-image logo-mobile"
          />
        </Link>
      </Navbar.Brand>

      <div className="btn d-lg-none mobile-header-bg"></div>
      {/* <Link to="/sign-up" className="btn d-lg-none signup-mobile">SIGN UP NOW</Link> */}

      <div className="nav-wrapper">
        <div className="pre-header text-decoration-none">
          <span className="d-none d-lg-inline d-xl-inline">
            <Link to="/heart-healthy-habits" className="btn">
              There&rsquo;s more you can do to lower the risk of a heart attack
              or stroke
            </Link>
          </span>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Navigation
            navContent={[
              { className: "heart" },
              {
                type: "link",
                target: "/heart-truths",
                content: "Heart Truths",
              },
              {
                type: "link",
                target: "/heart-videos",
                content: "Heart Videos",
              },
              {
                type: "link",
                target: "/ttyh-podcast",
                content: "TTYH Podcast",
              },
              {
                type: "link",
                target: "/heart-healthy-habits",
                content: "Heart-Healthy Habits",
              },
              // {
              //   type : "link",
              //   target: "/supplements",
              //   content: "Fish Oil Supplements"
              // },
              // {
              //   type : "link",
              //   target: "/sign-up",
              //   content: "Sign Up Now",
              //   className: "signup"
              // }
            ]}
          />
        </Navbar.Collapse>
      </div>
    </Navbar>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
