export default function navigationActive() {

	var url = window.location.pathname.replace(/\//g, '');
	var navitem = document.getElementsByClassName("nav-link");

	for (var i = 0; i < navitem.length; i++) {
	    var navitem_url = navitem[i].getAttribute("href");
	    if ( ("/" + url) == navitem_url) {
	    	navitem[i].classList.add("active");
	    }
	}	

}