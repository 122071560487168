import React from "react"
import 'bootstrap/dist/css/bootstrap.css';
import {Container,Row,Col} from 'react-bootstrap'
import "../../css/main.scss"
import Header from "../header/header"
import "../header/header.scss"
import Footer from "../footer/footer"
import "../footer/footer.scss"
import Loader from "../loader/loader"
import Player from '@vimeo/player';

export default class Layout extends React.Component{
  constructor(props){
    super(props);
    this.state={
      modalLink : "",
      modalContent : "",
      modalButton : "",
      modalState : "on"
    }
  }

  componentDidMount() {
    const modalElements = document.getElementsByClassName('modal-invoker')
    for (let i = 0; i < modalElements.length; i++) {
      modalElements[i].addEventListener('click', this.handleModalState)
    }
    const videosPlayButtons = document.querySelectorAll('.video-play-button');
    let playingVideo = undefined;
    if (videosPlayButtons !== undefined && videosPlayButtons.length >= 1) {
      videosPlayButtons.forEach(function (playerBtn) {
        if (playerBtn !== undefined && playerBtn !== null) {
          playerBtn.classList.remove('playing');
          const iframe = playerBtn.nextElementSibling;
          const player = new Player(iframe);
          playerBtn.addEventListener('click', function (evt) {
            player.play();
          });
          player.on('play', function () {
            playerBtn.classList.add('hide');
            if (playingVideo !== player && playingVideo !== undefined) {
              playingVideo.pause();
            }
            playingVideo = player;
          });
        }
      });
    }
  }



  componentDidUpdate(){
   var modalElements = document.getElementsByClassName('modal-invoker')
   for (var i = 0; i < modalElements.length; i++){
     modalElements[i].addEventListener('click',this.handleModalState)
   }

  }

  handleModalState = (event) => {
    var dataSet  = event.currentTarget.dataset
    this.setState({
      modalContent : dataSet.modalmessage,
      modalButton : dataSet.modalbuttontext,
      modalLink : dataSet.modalurl
    })
  }

  render(){
  return (
    <>
      <Loader/>
        <Header />

        <article>
          <Container fluid className="container-wrapper">
            <Row className="row-wrapper">
              <Col className="col-wrapper">
                {this.props.children}
              </Col>
            </Row>
          </Container>
        </article>



        <Footer displayStyle={this.props.displayStyle} vivCode={this.props.vivCode} />
    </>
  )
  }
}

Layout.defaultProps = {
  vivCode : "00373v9",
  displayStyle : "block",
}
